import React from 'react'
import { Link } from 'gatsby'
import logo_psi from '../../assets/images/logo_psi.png'
import footerMap from '../../assets/images/footer-map.png'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className='footer-area bg-color'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-sm-6'>
            <div className='single-footer-widget'>
              <a
                className='logo'
                target='_blank'
                rel='noreferrer'
                href='https://www.psi.ch/en'
              >
                <img src={logo_psi} alt='logo_psi' />
              </a>
              <p>
                ThermoEcos consists of codes and microservices connecting to
                ThermoHub - a property graph JSON database containing various
                thermodynamic datasets.
              </p>

              {/* <ul className='social-link'>
                <li>
                  <a
                    href='https://www.facebook.com/'
                    className='d-block'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='bx bxl-facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/'
                    className='d-block'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='bx bxl-twitter'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/'
                    className='d-block'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='bx bxl-instagram'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/'
                    className='d-block'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='bx bxl-linkedin'></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>

          <div className='col-lg-2 col-sm-6'>
            <div className='single-footer-widget pl-5'>
              <h3>Explore</h3>

              <ul className='footer-links-list'>
                {/* <li>
                  <Link to='/'>Home</Link>
                </li> */}
                <li>
                  <Link to='/about-us'>About</Link>
                </li>
                <li>
                  {/*   <i className='bx bx-plus'></i> */}
                  <a
                    href='https://thermohub.org/team'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Team
                  </a>
                </li>
                {/* <li>
                  <Link to='/case-studies'>Case Studies</Link>
                </li>
                <li>
                  <Link to='/blog'>Our Blog</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li> */}
              </ul>
            </div>
          </div>

          {/* <div className='col-lg-2 col-sm-6'>
            <div className='single-footer-widget'>
              <h3>Resources</h3>

              <ul className='footer-links-list'>
                <li>
                  <Link to='/team'>Our Scientists</Link>
                </li>
                <li>
                  <Link to='/services'>Our Services</Link>
                </li>
                <li>
                  <Link to='/testimonials'>Testimonials</Link>
                </li>
                <li>
                  <Link to='/services'>SaaS Solutions</Link>
                </li>
                <li>
                  <Link to='/case-studies'>Case Studies</Link>
                </li>
              </ul>
            </div>
          </div> */}

          <div className='col-lg-2 col-sm-6'>
            <div className='single-footer-widget'>
              <h3>More</h3>

              <ul className='footer-links-list'>
                <li>
                  <Link to='/faq'>FAQ</Link>
                </li>
                <li>
                  {/*    <i className='bx bx-plus'></i>  */}
                  <a
                    href='https://thermohub.org'
                    target='_blank'
                    rel='noreferrer'
                  >
                    ThermoHub.org
                  </a>
                </li>
                {/* <li>
                  <Link to='/services'>Our Services</Link>
                </li>
                <li>
                  <Link to='/testimonials'>Testimonials</Link>
                </li>
                <li>
                  <Link to='/services'>SaaS Solutions</Link>
                </li>
                <li>
                  <Link to='/case-studies'>Case Studies</Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className='col-lg-4 col-sm-6'>
            <div className='single-footer-widget'>
              <h3>Address</h3>

              <ul className='footer-contact-info'>
                {/* <li>
                  <i className='bx bx-map'></i>
                  Rinikerstrasse 18, Brugg, <br /> AG-5200, Switzerland
                </li> */}
                {/* <li>
                  <i className='bx bx-phone-call'></i>
                  <a href='tel:+41765433008'>+41 76 543 30 08</a>
                </li> */}
                <li>
                  <i className='bx bx-envelope'></i>
                  <a href='mailto:support@congineer.com'>
                    support@congineer.com
                  </a>
                </li>
                {/* <li>
                  <i className='bx bxs-inbox'></i>
                  <a href='tel:+41765433008'>+41 76 543 30 08</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className='footer-bottom-area'>
          <div className='row align-items-center'>
            <div className='col-lg-8 col-md-8'>
              <p>
                Copyright &copy; {currentYear} ThermoHub Team v0.1.0 | All
                rights reserved by{' '}
                <a
                  target='_blank'
                  href='https://congineer.com/'
                  rel='noreferrer'
                >
                  CONGINEER Ltd.
                </a>
              </p>
            </div>

            {/* <div className='col-lg-4 col-md-4'>
              <ul>
                <li>
                  <Link to='/privacy-policy'>Privacy Policy</Link>
                </li>
                <li>
                  <Link to='/terms-of-service'>Terms & Conditions</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <div className='footer-map'>
        <img src={footerMap} alt='footer-logo' />
      </div>
    </footer>
  )
}

export default Footer
