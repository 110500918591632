import React from 'react'
import SEO from '../components/App/SEO' /* eslint-disable */
import Navbar from '../components/App/Navbar'
import Footer from '../components/App/Footer'
import Layout from '../components/App/Layout'
import MainBanner from '../components/Home/MainBanner'
// import FeaturedServices from '../components/Home/FeaturedServices'
// import WhyChooseUs from '../components/Home/WhyChooseUs'
// import WhatWeDo from '../components/Home/WhatWeDo'
// import Funfacts from '../components/Home/Funfacts'
// import Services from '../components/Home/Services'
// import Testimonials from '../components/Home/Testimonials'
// import WeServe from '../components/Home/WeServe'
// import TeamMembers from '../components/Home/TeamMembers'
// import CaseStudy from '../components/Home/CaseStudy'
// import BlogPost from '../components/Home/BlogPost'
// import StartProject from '../components/Common/StartProject'

const Home = () => {
  return (
    <Layout>
      <SEO title='Home' />
      <Navbar />
      <MainBanner />
      {/* <FeaturedServices /> */}
      {/* <WhyChooseUs /> */}
      {/* <WhatWeDo /> */}
      {/* <Funfacts /> */}
      {/* <Services /> */}
      {/* <Testimonials /> */}
      {/* <WeServe /> */}
      {/* <TeamMembers /> */}
      {/* <CaseStudy /> */}
      {/* <BlogPost /> */}
      {/* <StartProject /> */}
      <Footer />
    </Layout>
  )
}

export default Home
